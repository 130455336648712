import React from 'react'
import {kebabCase} from 'lodash'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
// eslint-disable-next-line import/no-cycle
import Header from '../Header'
import Wrapper from '../Wrapper'
import website from '../../../config/website'
import {LocaleContext} from '../Layout'
import SEO from '../SEO'
import LocalizedLink from '../LocalizedLink'
import camo from '../../pages/camo.svg'
import Footer from '../Footer'

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 4rem;
  li {
    margin-bottom: 1.45rem;
    a {
      font-size: 1.5rem;
      font-style: normal;
      color: ${props => props.theme.colors.mainColor};
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const Hero = styled.header`
  h1 {
    color: ${props => props.theme.colors.fontDark};
    text-align: center;
    padding: 80px 0 40px 0;
    margin: 0;
    font-size: 36px;
    font-weight: 800;
  }
`

// eslint-disable-next-line react/prop-types
const Listing = ({ data: { generators, posts }, pageContext: { locale }, location }) => {
  const kind = generators.edges[0].node.uid
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const renderFormula = (f, p) => {
    const { country, operator, device, preposition } = p
    // eslint-disable-next-line no-eval
    return eval(`\`${f}\``)
  }

  const renderHeader = () => {
    const { formula } = generators.edges[0].node.data
    switch (kind) {
      case 'country':
        return renderFormula(formula, { country: i18n.travel })
      case 'device':
        return renderFormula(formula, {
          device: `<span>${i18n.device}</span>`,
        })
      case 'operator_country':
        return renderFormula(formula, {
          operator: '',
          country: '',
          preposition: ' ',
        })
      case 'operator_device':
        return renderFormula(formula, {
          operator: '',
          device: '',
          preposition: ' ',
        })
      default:
        return null
    }
  }

  const renderPostName = post => {
    const { formula } = generators.edges[0].node.data
    const { country, operator, vendor, device } = post.node.data
    switch (kind) {
      case 'country':
        return renderFormula(formula, {
          country: country.document[0].data.alt_name || country.document[0].data.full_name,
          preposition: country.document[0].data.alt_preposition,
        })
      case 'device':
        return renderFormula(formula, {
          device: `${vendor.document[0].data.full_name} ${post.node.data.model}`,
        })
      case 'operator_country':
        return renderFormula(formula, {
          country: country.document[0].data.alt_name || country.document[0].data.full_name,
          operator: operator.document[0].data.full_name,
          preposition: country.document[0].data.alt_preposition,
        })
      case 'operator_device':
        return renderFormula(formula, {
          operator: operator.document[0].data.full_name,
          device: device.document[0].data.full_name,
        })
      default:
        return null
    }
  }

  const renderPostSlug = post => {
    // eslint-disable-next-line camelcase
    const { formula_slug } = generators.edges[0].node.data
    const { country, operator, vendor, device } = post.node.data

    switch (kind) {
      case 'country':
        return renderFormula(formula_slug, {
          country: country.document[0].uid,
        })
      case 'device':
        return renderFormula(formula_slug, {
          device: kebabCase(`${vendor.document[0].data.full_name} ${post.node.data.model}`),
        })
      case 'operator_country':
        return renderFormula(formula_slug, {
          country: country.document[0].uid,
          operator: kebabCase(operator.document[0].data.full_name),
          preposition: country.document[0].data.alt_preposition,
        })
      case 'operator_device':
        return renderFormula(formula_slug, {
          operator: operator.document[0].uid,
          device: kebabCase(device.document[0].data.full_name),
        })
      default:
        return null
    }
  }

  const header = renderHeader()
  const title = header

  return (
    <>
      <SEO
        title={`${title} ${i18n.defaultTitleAlt} ${i18n.defaultTitleAddon}`}
        pathname={location.pathname}
        locale={locale}
      />
      <Hero>
        <Wrapper>
          <Header dark />
          <h1 dangerouslySetInnerHTML={{ __html: header }} />
        </Wrapper>
      </Hero>
      <Wrapper id={website.skipNavId}>
        <ProjectListing>
          {posts.edges.map(post => (
            <li key={post.node.id}>
              <LocalizedLink to={`/${renderPostSlug(post)}`}>{renderPostName(post)}</LocalizedLink>
            </li>
          ))}
        </ProjectListing>
      </Wrapper>
    </>
  )
}

Listing.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Listing
