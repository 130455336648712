import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { LocaleContext } from './Layout'
import LocalizedLink from './LocalizedLink'
import { IconLogo } from './Icons'
import LocaleSwitcher from './LocaleSwitcher'

const Root = styled.header`
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled(LocalizedLink)`
  display: inline-block;
`

const Header = props => {
  const lang = React.useContext(LocaleContext)
  const langActive = lang.i18n[lang.locale].siteLanguage
  const { dark, location } = props
  return (
    <Root>
      <Logo to="/">
        <IconLogo dark={dark} />
      </Logo>
      <LocaleSwitcher dark={dark} location={location} langActive={langActive} />
    </Root>
  )
}

Header.propTypes = {
  dark: PropTypes.bool,
  location: PropTypes.object,
}

Header.defaultProps = {
  dark: false,
  location: null,
}

export default Header
