import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Content = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`

const Image = ({ input }) => {
  const { alt, localFile } = input.primary.image
  const sources = [
    localFile.childImageSharp.sizes,
    {
      ...localFile.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <Content>
      <Img alt={alt} fluid={sources} />
    </Content>
  )
}

export default Image

Image.propTypes = {
  input: PropTypes.object.isRequired,
}
