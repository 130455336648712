import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {IconSelectBox} from '../components/Icons'
import ReviewItem from '../components/ReviewItem'

const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidthText};
  margin: 0 auto;
`

const OperatorName = styled.h3`
  margin: 0 0 1rem 0;
  font-size: 20px;
  color: #424242;
`

const AggregateRating = styled.p`
  color: #424242;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  font-size: 15px;
  line-height: 1.58;
`

const ThankMessage = styled.p`
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  margin: 0;
  padding: 40px 0;
`

const RatingBlock = styled.div`
  float: left;
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  p {
    margin: 0 10px 0 0;
  }
`

const Textarea = styled.textarea`
  width: 100%;
  color: #1c252b;
  border: 1px solid #dadce0;
  outline: none;
  padding: 10px;
  font-size: 15px;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 80px;
  height: 80px;
  &:focus {
    outline: none;
  }
`
const Input = styled.input`
  width: 40%;
  color: #1c252b;
  border: 1px solid #dadce0;
  outline: none;
  padding: 10px;
  font-size: 15px;
  box-sizing: border-box;
  height: 30px;
  margin: 0 0 10px 0;
  &:focus {
    outline: none;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
`

const Button = styled.button`
  color: #fff;
  cursor: pointer;
  margin: 20px 0 0 0;
  display: inline-block;
  outline: none;
  padding: 6px 30px;
  font-size: 14px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ee4256;
  border: none;
  float: right;
  transition: 0.2s;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #60003f;
  }
`

const Label = styled.label`
  background-color: #f5f3f6;
  margin: 0;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  svg {
    top: 50%;
    right: 10px;
    width: 9px;
    height: 9px;
    margin: -2px 0 0 0;
    position: absolute;
  }
`
const Select = styled.select`
  cursor: pointer;
  padding: 5px 30px 6px 10px !important;
  color: #424242 !important;
  width: auto !important;
  border: none;
  height: auto;
  margin: 0;
  display: block;
  pading: 0 !important;
  font-size: 15px !important;
  word-wrap: normal;
  font-weight: 300;
  moz-appearance: none;
  background-color: transparent;
  webkit-appearance: none;
  -webkit-appearance: none;
  -webkit-backface-visibility: hidden;
  &:focus {
    outline: none;
  }
`

class Reviews extends React.PureComponent {
  static propTypes = {
    input: PropTypes.object,
    operatorName: PropTypes.string,
    deviceName: PropTypes.string,
    i18n: PropTypes.object.isRequired,
    noReviews: PropTypes.bool,
  }

  static defaultProps = {
    noReviews: false,
    input: [],
    operatorName: '',
    deviceName: '',
  }

  state = {
    reviewsState: true,
  }

  renderFormula = (f, p) => {
    const { averageRating, reviewCount, deviceName } = p
    // eslint-disable-next-line no-eval
    return eval(`\`${f}\``)
  }

  sendReviews = e => {
    e.preventDefault()
    this.setState({ reviewsState: false })
  }

  render() {
    const { operatorName, deviceName, input, i18n, noReviews } = this.props
    const { reviewsState } = this.state
    const reviewCount = !noReviews ? input.items.length : 0
    let averageRating = 0
    // eslint-disable-next-line no-return-assign
    if (!noReviews) {
      // eslint-disable-next-line no-return-assign
      input.items.map(v => (averageRating += parseFloat(v.rating)))
    }
    averageRating /= parseFloat(reviewCount)
    averageRating = !noReviews ? averageRating.toFixed(1) : 0
    const aggregateRatingContent = this.renderFormula(i18n.reviewsRated, {
      averageRating,
      reviewCount,
    })
    const titleDevice = this.renderFormula(i18n.reviewsTitleDevice, {
      deviceName,
    })
    return (
      <Wrapper>
        {!noReviews ? (
          <div vocab="http://schema.org/" typeof="Organization">
            {operatorName && (
              <OperatorName
                dangerouslySetInnerHTML={{
                  __html: `${i18n.reviewsTitle} <span property="name">${operatorName}</span>`
                }}
              />
            )}

            {deviceName && (
              <OperatorName
                dangerouslySetInnerHTML={{
                  __html: titleDevice
                }}/>
            )}

            <AggregateRating
              property="aggregateRating"
              typeof="AggregateRating"
              dangerouslySetInnerHTML={{ __html: aggregateRatingContent }}
            />
            {input.items.map(v => (
              <ReviewItem author={v.author} rating={v.rating} dateReviewed={v.date_reviewed}
                          review={v.review.text}/>
            ))}
            {reviewsState ? (
              <div>
                <form
                  method="POST"
                  onSubmit={e => {
                    this.sendReviews(e)
                  }}
                >
                  <Input placeholder={i18n.reviewsNamePlaceholder} name="name" required/>
                  <Textarea placeholder={i18n.reviewsTextPlaceholder} name="text" required/>
                  <RatingBlock>
                    <p>{i18n.rating}</p>
                    <Label aria-label="Rating">
                      <IconSelectBox/>
                      <Select required>
                        <option></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Select>
                    </Label>
                  </RatingBlock>
                  <Button type="submit">{i18n.send}</Button>
                </form>
              </div>
            ) : (
              <ThankMessage>{i18n.reviewsThank}</ThankMessage>
            )}
          </div>
        ) : (
          <div>
            {operatorName && (
              <OperatorName
                dangerouslySetInnerHTML={{
                  __html: `${i18n.reviewsTitle} <span property="name">${operatorName}</span>`
                }}
              />
            )}

            {deviceName && (
              <OperatorName
                dangerouslySetInnerHTML={{
                  __html: titleDevice
                }}/>
            )}
            <AggregateRating dangerouslySetInnerHTML={{ __html: aggregateRatingContent }}/>
            {reviewsState ? (
              <div>
                <form
                  method="POST"
                  onSubmit={e => {
                    this.sendReviews(e)
                  }}
                >
                  <Input placeholder={i18n.reviewsNamePlaceholder} name="name" required/>
                  <Textarea placeholder={i18n.reviewsTextPlaceholder} name="text" required/>
                  <RatingBlock>
                    <p>{i18n.rating}</p>
                    <Label aria-label="Rating">
                      <IconSelectBox/>
                      <Select required>
                        <option></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Select>
                    </Label>
                  </RatingBlock>
                  <Button type="submit">{i18n.send}</Button>
                </form>
              </div>
            ) : (
              <ThankMessage>{i18n.reviewsThank}</ThankMessage>
            )}
          </div>
        )}
      </Wrapper>
    )
  }
}

export default Reviews
