import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import LocalizedLink from './LocalizedLink'
import RatingStars from './RatingStars'

const Review = styled.div`
  font-size: 15px;
  line-height: 1.58;
`

const Author = styled.span`
  font-weight: bold;
  color: #424242;
  display: inline-block;
`

const HeadingRating = styled.span`
  display: flex;
  align-items: center;
`

const DatePublished = styled.span`
  margin: 0 0 0 10px;
`

const ReviewItem = ({ author, rating, dateReviewed, review }) => (
  <Review property="review" typeof="Review">
    <HeadingRating>
      <Author property="author">{author}</Author>
      <span property="reviewRating" typeof="Rating">
        <span property="worstRating" content="1" />
        <span property="ratingValue" content={rating} />
        <span property="bestRating" content="5" />
      </span>
      <RatingStars rating={rating} />
      <DatePublished property="datePublished" content={dateReviewed}>
        {dateReviewed}
      </DatePublished>
    </HeadingRating>
    <p property="description" dangerouslySetInnerHTML={{ __html: review }} />
  </Review>
)

ReviewItem.propTypes = {
  author: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  dateReviewed: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
}

export default ReviewItem
