import React from 'react'
import PropTypes from 'prop-types'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import styled from '@emotion/styled'
import WidgetComponet from '@argh/esim-widget/lib/components/Widget'
import { graphql, useStaticQuery } from 'gatsby'
import { withCookies } from 'react-cookie'
import { convertEdgesToPlans } from '../utils/helpers/convert-edges-to-plans'
import { planToOldFields } from '../utils/helpers/plan-to-old-fields'

const Container = styled.div`
  margin: 40px auto 0 auto;
`
const Button = styled.button`
  color: #fff;
  cursor: pointer;
  margin: 20px 0 0 0;
  display: inline-block;
  outline: none;
  padding: 6px 30px;
  font-size: 14px;
  transition: 0.2s;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ee4256;
  border: none;
  :focus {
    outline: none;
  }
  :hover {
    background-color: #60003f;
  }
`

if (typeof window === 'undefined') {
  global.window = { location: { search: '' } }
}

const checkStatus = response => response.json().then(json => ({ json, response }))
const parseJSON = ({ json, response }) => (response.ok === false ? { errorCode: response.status, ...json } : json)
const Get = url =>
  fetch(url, {
    credentials: 'include',
  })
    .then(checkStatus)
    .then(parseJSON)

const toQueryString = object => {
  const query = Object.keys(object)
    .filter(key => !!object[key])
    .map(key => {
      if (object[key] && object[key] !== 'null') {
        return `${key}=${object[key].toString()}`
      }
      return ''
    })
    .join('&')
  return `?${query}`
}

const query = graphql`
  query {
    allInternalPlans {
      edges {
        node {
          plans {
            alternative_id
            providerId
            name
            capacity
            period
            provider {
              name
              slug
              url
              iconUrl
              directUrl
            }
            planCosts {
              planId
              currency
              cost
            }
          }
        }
      }
    }
  }
`

const getPlans = edges => convertEdgesToPlans(edges, 'EUR')

const onSendEvent = ({ eventCategory, eventAction, eventLabel, dimensions }) => {
  trackCustomEvent({
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
    // value: eventValue,
    ...dimensions,
  })
}

const onGetPlans = async ({ countryCode, operatorId, currency }) => {
  const url = process.env.NODE_ENV === `production` ? '/api' : 'https://api.esim.ninja'
  const { success, plans } = await Get(
    `${url}/search${toQueryString({
      all: false,
      ...(countryCode && { countryCode }),
      ...(operatorId && { providerId: operatorId }),
    })}`
  )

  return {
    success,
    plans: plans.map(plan => planToOldFields(plan, currency)),
  }
}

// eslint-disable-next-line react/prop-types
const ExternalButton = ({ children, onClick }) => <Button onClick={onClick}>{children}</Button>

const getUrlParams = (search = window.location.search) => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  const params = {}
  // eslint-disable-next-line array-callback-return
  hashes.map(hash => {
    const [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })
  if (params.operator) {
    params.operator = params.operator.split(',')
  }
  return params
}

const modeByQueries = queries => {
  const keys = ['partnerId', 'countryCode', 'operatorId', 'currency', 'size', 'days', 'operator']
  let mode = null
  if (queries) {
    // eslint-disable-next-line no-restricted-syntax=
    for (const key in queries) {
      if (key && keys.indexOf(key) >= 0) {
        mode = 'full'
      }
    }
  }
  return mode
}

@withCookies
class Widget extends React.Component {
  static propTypes = {
    darkTheme: PropTypes.bool,
    country: PropTypes.string,
    currency: PropTypes.string,
    size: PropTypes.number,
    days: PropTypes.number,
    sortings: PropTypes.string,
    sortingState: PropTypes.string,
    sortingStateLabel: PropTypes.string,
    items: PropTypes.number,
    mode: PropTypes.string,
    language: PropTypes.string,
    operator: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filters: PropTypes.bool,
    plans: PropTypes.array,
    cookies: PropTypes.object.isRequired,
    operatorId: PropTypes.string,
  }

  static defaultProps = {
    darkTheme: false,
    country: null,
    currency: 'USD',
    size: 2,
    days: 5,
    sortings: 'priceShort',
    sortingState: 'Short',
    sortingStateLabel: 'price',
    items: 5,
    mode: 'collapsed-items',
    operator: null,
    filters: true,
    language: 'en',
    plans: [],
    operatorId: null,
  }

  constructor(props) {
    super(props)
    const queries = getUrlParams()
    const {
      country,
      operatorId,
      currency,
      size,
      days,
      sortings,
      sortingState,
      sortingStateLabel,
      items,
      mode,
      operator,
      filters,
      plans,
      darkTheme,
      cookies,
      language,
    } = props
    const { pid } = cookies.getAll()
    if (queries.partnerId) {
      const expires = new Date()
      expires.setDate(expires.getDate() + 7)
      cookies.set('pid', queries.partnerId, { path: '/', expires })
    }
    const countryCode = queries.countryCode || country
    const operatorIdWidget = queries.operatorId || operatorId
    this.state = {
      options: {
        darkTheme,
        disclaimer: false,
        partnerId: queries.partnerId || pid,
        countryCode: queries.countryCode || country,
        operatorId: operatorIdWidget,
        currency: queries.currency || currency,
        size: queries.size || size,
        days: queries.days || days,
        sortings: queries.sortings || sortings,
        sortingState: queries.sortingState || sortingState,
        sortingStateLabel: queries.sortingStateLabel || sortingStateLabel,
        items: queries.items || items,
        operator: queries.operator || operator,
        filters,
        plans: countryCode || operatorIdWidget ? null : plans,
        language,
        /*
          mode property
          'collapsed-items' - show all items on "Show all..." click,
          'redirect' - redirect if "Show all..." click. is default property value,
          'full' - full list
       */
        mode: modeByQueries(queries) || mode || 'collapsed-items',
      },
    }
  }

  render() {
    const { options } = this.state
    return (
      <Container>
        <WidgetComponet onGetPlans={onGetPlans} onSendEvent={onSendEvent} {...options} button={ExternalButton} />
      </Container>
    )
  }
}

const PlansWrapper = props => {
  const data = useStaticQuery(query)

  const {
    allInternalPlans: { edges },
  } = data
  const plans = getPlans(edges)

  return <Widget {...props} plans={plans.map(p => ({ ...p, id: p.alternative_id }))} />
}

export default PlansWrapper
