import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { IconStarOn, IconStarOff, IconStarHalf } from './Icons'

const Root = styled.div`
  display: inline-block;
  margin: -6px 0 0 10px;
`
const Star = styled.div`
  width: 15px;
  display: block;
  float: left;
`

const RatingStars = ({ rating }) => {
  const star = 5
  let starOn
  const createStars = () => {
    let stars = []
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= star; i++) {
      if (rating >= i) {
        stars.push(
          <Star>
            <IconStarOn />
          </Star>
        )
        starOn = i
      } else if (starOn + 1 === i) {
        if (rating - starOn > 0) {
          stars.push(
            <Star>
              <IconStarHalf />
            </Star>
          )
        } else {
          stars.push(
            <Star>
              <IconStarOff />
            </Star>
          )
        }
      } else {
        stars.push(
          <Star>
            <IconStarOff />
          </Star>
        )
      }
    }
    return stars
  }
  return <Root>{createStars()}</Root>
}

RatingStars.propTypes = {
  rating: PropTypes.number.isRequired,
}

export default RatingStars
