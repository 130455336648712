import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Content = styled.div`
  width: 45%;
  float: right;
  padding: 0 0 0 1rem;
  @media (max-width: 500px) {
    width: 100%;
    float: none;
    padding: 0 0 1.5rem 0;
  }
`

const ImagePreface = ({ input }) => {
  const {
    node: { id, fluid, sizes },
  } = input[0]
  const sources = [
    sizes,
    {
      ...fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <Content>
      <Img alt={id} fluid={sources} />
    </Content>
  )
}

export default ImagePreface

ImagePreface.propTypes = {
  input: PropTypes.object.isRequired,
}
