import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {graphql, useStaticQuery} from 'gatsby'
import {BodyText, CodeBlock, Image, Quote, ImagePreface, Reviews} from '../slices'
import Widget from './Widget'
import {prism} from '../styles'
import {LocaleContext} from './Layout'
import {localizedSlug} from '../utils/gatsby-node-helpers'

const Content = styled.div`
  ${prism};
  p,
  li {
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    font-size: 15px;
    line-height: 1.58;
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
  }
  h1 {
    color: ${props => props.theme.colors.fontDark};
    margin: 0 0 2rem 0;
    font-size: 36px;
    font-weight: 800;
  }
  h2 {
    margin: 0 0 1rem 0;
    font-size: 26px;
  }
  h3 {
    margin: 0 0 1rem 0;
    font-size: 20px;
  }
  blockquote {
    margin-left: 0;
    padding-left: 1.45rem;
    border-left: 2px solid ${props => props.theme.colors.primary};
    p {
      font-size: 1rem;
      font-style: italic;
    }
  }
`

const ContentPreface = styled.div`
  max-width: ${props => props.theme.maxWidthText};
  margin: 0 auto;
  padding: 0 0 1.5rem 0;
  &:after {
    content: '';
    clear: both;
    display: block;
  }
`

const ContentRightText = styled(Content)`
  p,
  ol {
    :first-child {
      float: right;
      margin: 0 0 0 60px;
      @media (max-width: 500px) {
        float: none;
        margin: 0 0 0 1.45rem;
      }
    }
  }
`
const WidgetHolder = styled.div`
  max-width: 900px;
  margin: 0 auto 2em auto;
`

const transformerTag = (data, tag) => {
  // eslint-disable-next-line no-useless-escape
  const regexp = `(\<[^>])*>{${tag}_#(.*?)\}`
  const re = new RegExp(regexp, 'g')
  return data.replace(re, `$1 ${tag}="$2">`)
}

const ucFirst = (str) => {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1)
}

const htmlSerializer = (raw, allPostCountry) => {
  const textParsed = raw.map((s, index, elements) => {
    let text = s.text
    if (!!s.spans && s.spans.length > 0) {
      text = text.split('')
      s.spans.map(link => {
        const linkStart = link.start === 0 ? 0 : link.start - 1
        switch (link.type) {
          case 'hyperlink':
            if (link.data.link_type === 'Document') {
              let slug = localizedSlug({
                uid: link.data.slug,
                lang: link.data.lang,
              })
              if (link.data.type === 'post_country') {
                const idParsed = link.data.type.split(/_(.*)/g)
                const postLink = allPostCountry.edges.filter(
                  item => item.node.id === `Prismic__${ucFirst(idParsed[0])}${ucFirst(idParsed[1])}__${link.data.id}`
                )
                slug = localizedSlug({
                  uid: `esim-in-${postLink[0].node.data.country.document[0].uid}`,
                  lang: link.data.lang,
                })
              }
              if (link.data.type === 'post_device') {
                slug = localizedSlug({
                  uid: `${link.data.slug}-esim`,
                  lang: link.data.lang,
                })
              }
              if (link.data.type === 'post_operator') {
                slug = localizedSlug({
                  uid: `${link.data.slug}-esim-reviews`,
                  lang: link.data.lang,
                })
              }
              if (link.data.type === 'blog') {
                slug = localizedSlug({
                  uid: `blog/${link.data.uid}`,
                  lang: link.data.lang,
                })
              }
              text.splice(linkStart, 1, ` <a href='${slug}'>`)
              text.splice(link.end, 1, '</a> ')
            }
            if (link.data.link_type === 'Web') {
              text.splice(
                linkStart,
                1,
                ` <a ${link.data.target === '_blank' ? 'target="_blank"' : ''} href='${link.data.url}'>`
              )
              text.splice(link.end, 1, '</a> ')
            }
            return null
          case 'strong':
            text.splice(linkStart, 1, ` <strong>`)
            text.splice(link.end, 1, '</strong> ')
            return null
          case 'em':
            text.splice(linkStart, 1, ` <em>`)
            text.splice(link.end, 1, '</em> ')
            return null
          default:
            return null
        }
      })
      text = text.join('')
    }
    switch (s.type) {
      case 'heading1':
        return `<h1>${text}</h1>`
      case 'heading2':
        return `<h2>${text}</h2>`
      case 'heading3':
        return `<h3>${text}</h3>`
      case 'heading4':
        return `<h4>${text}</h4>`
      case 'heading5':
        return `<h5>${text}</h5>`
      case 'heading6':
        return `<h6>${text}</h6>`
      case 'paragraph':
        return `<p>${text}</p>`
      case 'list-item':
        return `${!!elements[index - 1] && elements[index - 1].type !== 'list-item' ? '<ul>' : ''} <li>${text}</li>${
          !!elements[index + 1] && elements[index + 1].type !== 'list-item' ? '</ul>' : ''
        }`
      case 'o-list-item':
        return `${!!elements[index - 1] && elements[index - 1].type !== 'o-list-item' ? '<ol>' : ''} <li>${text}</li>${
          !!elements[index + 1] && elements[index + 1].type !== 'o-list-item' ? '</ol>' : ''
        }`
      default:
        return null
    }
  })
  return textParsed.join('')
}

const SliceZone = ({ allSlices, allPostCountry, operatorName, deviceName, widgetProps, socialCard }) => {
  const data = useStaticQuery(graphql`
    query OgImageQuery{
      allImageSharp{
        edges {
          node {
            id
            fluid(maxWidth: 1200, quality: 90) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
            sizes(maxWidth: 700) {
              ...GatsbyImageSharpSizes_withWebp
            }
          }
        }
      }
    }
  `)
  const ImagePrefaceOg = data.allImageSharp.edges.filter(s => s.node.fluid.originalName === socialCard)
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  let slice = null
  if (allSlices) {
    slice = allSlices.map(s => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'text':
          let result = { primary: { text: {} } }
          result.primary.text.html = htmlSerializer(s.primary.text.raw, allPostCountry)
          result.primary.text.html = transformerTag(transformerTag(result.primary.text.html, 'id'), 'class').replace(/https:\/\/#/gi, '#') // we replace https: # with # because the prismic does not transmit clean links without https:
          return (
            <Content key={s.id}>
              <BodyText input={result}/>
            </Content>
          )
        case 'left_text':
          let leftTextResult = { primary: { text: {} } }
          leftTextResult.primary.text.html = transformerTag(transformerTag(s.primary.text.html, 'id'), 'class').replace(/https:\/\/#/gi, '#')
          return (
            <ContentRightText key={s.id}>
              <BodyText input={leftTextResult}/>
            </ContentRightText>
          )
        case 'code_block':
          return (
            <Content key={s.id}>
              <CodeBlock input={s}/>
            </Content>
          )
        case 'image':
          return (
            <Content key={s.id}>
              <Image input={s}/>
            </Content>
          )
        case 'quote':
          return (
            <Content key={s.id}>
              <Quote input={s}/>
            </Content>
          )
        case 'widget':
          return (
            <WidgetHolder>
              <Widget key={s.id} {...widgetProps} language={lang.locale}/>
            </WidgetHolder>
          )
        case 'reviews':
          return (
            <Content key={s.id}>
              <Reviews input={s} operatorName={operatorName} deviceName={deviceName} i18n={i18n}/>
            </Content>
          )
        case 'preface':
          return (
            <Content key={s.id}>
              <ContentPreface>
                {!!ImagePrefaceOg[0] && <ImagePreface input={ImagePrefaceOg}/>}
                <div dangerouslySetInnerHTML={{ __html: s.primary.text.html }}/>
              </ContentPreface>
            </Content>
          )
        default:
          return null
      }
    })
  }
  return slice
}

export default SliceZone

SliceZone.propTypes = {
  allSlices: PropTypes.array,
  socialCard: PropTypes.string,
}

SliceZone.defaultProps = {
  allSlices: [],
  socialCard: null,
}
