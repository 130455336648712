import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { LocaleContext } from './Layout'

const LocaleSwitcherContainer = styled.div`
  a {
    font-size: 15px;
    color: #fff;
    margin: 0 0 0 5px;
    padding: 5px;
    &.active {
      opacity: 0.4;
    }
    &:hover {
      opacity: 1;
    }
  }
`

const LocaleSwitcher = props => {
  let pathName
  const { dark, location, langActive } = props
  const lang = React.useContext(LocaleContext)
  const locales = Object.keys(lang.i18n).map(locale => {
    const { default: isDefault, path, siteLanguage: language } = lang.i18n[locale]
    return { locale, isDefault, path, language }
  })
  if (location) {
    pathName = location.pathname.replace(/\/ru\//gi, '')
  } else {
    pathName = '/'
  }
  return (
    <LocaleSwitcherContainer data-name="locale-switcher">
      {locales.map(({ locale, isDefault, path, language }) => (
        <Link
          disabled
          key={locale}
          className={language !== langActive ? 'active' : ''}
          style={dark ? { color: '#000' } : { color: '#FFF' }}
          hrefLang={locale}
          to={
            isDefault
              ? `${language !== langActive ? '/' : ''}${pathName}`
              : `/${path}${language === langActive ? '/' : ''}${pathName}`
          }
        >
          {language.toUpperCase()}
        </Link>
      ))}
    </LocaleSwitcherContainer>
  )
}

LocaleSwitcher.propTypes = {
  dark: PropTypes.bool,
  location: PropTypes.object,
  langActive: PropTypes.string,
}

LocaleSwitcher.defaultProps = {
  dark: false,
  location: null,
  langActive: '',
}

export default LocaleSwitcher
