import { planConst } from './plan-const'

const getImageFilename = url => (url ? url.substring(url.lastIndexOf('/') + 1) : null)

export const planToOldFields = (plan, currency = 'EUR') => {
  const image = getImageFilename(plan.provider.iconUrl)

  return {
    id: plan.id || plan.alternative_id,
    operatorId: plan.providerId,
    operatorName: plan.provider.name,
    url: plan.url || plan.provider.url || plan.provider.directUrl,
    name: plan.name,
    size: plan.capacity,
    days: plan.period,
    priceBase: planConst(plan.planCosts, 'EUR'),
    priceConverted: planConst(plan.planCosts, currency),
    countryCodes: [],
    icon: image ? `https://ik.imagekit.io/ohteqch3au9/providers/${image}` : null,
  }
}
