import { planToOldFields } from './plan-to-old-fields'

export const convertEdgesToArray = (edges = []) => {
  const edge = edges.filter(e => e.node.plans && e.node.plans.length)[0] || {}
  return edge && edge.node && edge.node.plans ? edge.node.plans : []
}

export const convertEdgesToPlans = (edges = [], currency) => {
  const plans = convertEdgesToArray(edges)
  return plans.map(plan => planToOldFields(plan, currency))
}
